import theme from "@chakra-ui/gatsby-plugin/theme"
import { Box, Flex, FlexProps, Text, TextProps } from "@chakra-ui/react"
import Footer from "components/layout/Footer"
import Header from "components/layout/Header"
import HeaderBg from "components/layout/HeaderBg"
import Layout from "components/layout/Layout"
import LayoutBase from "components/layout/LayoutBase"
import SEO from "components/layout/SEO"
import Link from "components/ui/Link"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Greeting = (props: TextProps) => (
  <Text {...props}>
    Hi, I'm Michael.
    <br />I can help you build digital products. <br />
    See my{" "}
    <Link
      p={1}
      bgColor="secondary.main"
      href="/services"
      isGatsby={true}
      color="black"
    >
      offer.
    </Link>
  </Text>
)

const Hero = (props: FlexProps) => (
  <Flex
    flexDirection={{ base: "column-reverse", md: "row" }}
    alignItems="center"
    mx="auto"
    bgColor="primary.main"
    px={{ base: 4, md: 14 }}
    {...props}
  >
    <Greeting
      flex="1 1 50%"
      display="inline-block"
      whiteSpace="break-spaces"
      fontSize={{ base: "2xl", sm: "4xl", md: "4xl", lg: "5xl" }}
      minW="250px"
      maxW="600px"
      px={{ base: 12, md: 0 }}
    />
    <Box py={6} px={3} display={{ base: "block", md: "none" }} />
    <Box py={6} px={6} display={{ base: "none", md: "block" }} />
    <Box
      backgroundColor={theme.colors.secondary.main}
      borderRadius="50%"
      filter="drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.3))"
      boxSize={{ base: "min(max(38vw,200px),300px)", md: "min(33vw,300px)" }}
    >
      <StaticImage
        quality={100}
        alt="Hi there!"
        src="../../assets/profile-circle.webp"
        placeholder="none"
        imgStyle={{
          borderRadius: "50%",
          objectFit: "contain",
        }}
        style={{
          borderRadius: "50%",
        }}
        height={300}
      />
    </Box>
  </Flex>
)

const Home = (props: PageProps) => {
  return (
    <LayoutBase bgColor="primary.main">
      <Box py={6} w="100%" />
      <Header
        logoProps={{ boxSize: { base: 12, md: 14 } }}
        location={props.location}
        overflow="hidden"
      />
      <HeaderBg bgColor="primary.main" position="absolute" top="0" />
      <Flex flex="1 1 auto" flexDirection="column">
        <SEO />
        <Hero pt={{ base: 12, md: 24 }} />
      </Flex>
      <Footer />
    </LayoutBase>
  )
}

export default Home
